import React, {useEffect, useState} from 'react';
import '../../../assets/css/main.css';
import MBTI from './MBTI';
import GarnerMIT from './GarnerMIT';
import ClifftonStrength from './ClifftonStrength';
import {Link, useParams} from "react-router-dom";

const TestResultList = () => {
	const [selectedCard, setSelectedCard] = useState('MBTI'); // Изначально выбран MBTI
	const {user_id} = useParams();
	const {section} = useParams();

	useEffect(() => {
		setSelectedCard(section)
	}, []);
	useEffect(() => {
		window.history.replaceState("", "", `/test_result/${user_id}/${selectedCard}`)
	}, [selectedCard]);

	const handleLinkClick = (cardName) => {
		setSelectedCard(cardName);
	};

	const getHeader = () => {
		switch (selectedCard) {
			case 'MBTI':
				return 'MBTI (16 personalities)';
			case 'GarnerMIT':
				return 'GARDNER MIT';
			case 'ClifftonStrength':
				return 'CliftonStrength 34';
			default:
				return 'MBTI (16 personalities)';
		}
	};

	return (
		<div className={`bg-[#eaf8fc] min-h-screen`}>
			<div className="container-dashboard mx-auto flex flex-col pb-8">
				<div className="grid grid-cols-1 lg:grid-cols-12 gap-x-2 gap-y-4">
					<Link to={`/profile/${user_id}`}
								className='lg:col-span-3 col-span-12 order-first flex justify-start items-center text-start'>
						Вернуться к профилю
					</Link>
					<div
						className='lg:col-span-9 col-span-12 flex text-start w-full order-3 lg:order-2
						lg:text-[32px] lg:leading-[37.6px] text-[20px] leading-[23.5px] font-bold'>
						{getHeader()}
					</div>
					<div
						className="col-span-12 lg:col-span-3 flex lg:flex-col lg:items-start items-center sm:justify-start justify-between order-2 lg:order-3 gap-y-2
						text-black font-bold text-start *:py-2 sm:*:px-4 *:px-2 lg:*:rounded-[15px] *:rounded-[5px] *:transition-colors lg:*:-translate-x-4 *:cursor-pointer
						lg:text-[24px] lg:leading-[28.2px] text-[14px] leading-[16.45px]">
						<div
							className={`lg:block hidden ${selectedCard === 'MBTI' ? 'bg-white' : ''}`}
							onClick={() => handleLinkClick('MBTI')}>
							MBTI (16 personalities)
						</div>
						<div
							className={`lg:hidden ${selectedCard === 'MBTI' ? 'bg-white' : ''}`}
							onClick={() => handleLinkClick('MBTI')}>
							MBTI
						</div>
						<div
							className={`${selectedCard === 'GarnerMIT' ? 'bg-white' : ''}`}
							onClick={() => handleLinkClick('GarnerMIT')}>GARDNER MIT
						</div>
						<div
							className={`${selectedCard === 'ClifftonStrength' ? 'bg-white' : ''} break-all`}
							onClick={() => handleLinkClick('ClifftonStrength')}>CliftonStrength 34
						</div>
					</div>
					<div className="col-span-12 lg:col-span-9 order-4 lg:p-12 p-4 rounded-[16px] bg-white">
						{selectedCard === 'MBTI' && <MBTI/>}
						{selectedCard === 'GarnerMIT' && <GarnerMIT/>}
						{selectedCard === 'ClifftonStrength' && <ClifftonStrength/>}
					</div>
				</div>
			</div>
		</div>
	);
}

export default TestResultList;
