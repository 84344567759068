import React, { useState, useEffect, useRef } from 'react';
import { XMarkIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import '../../../assets/css/main.css';
import UserCard from '../main/UserCard';
import { Radar, Pie } from 'react-chartjs-2';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { fetchGallupById, fetchGallupProfReports } from '../../../actions/gallupActions';
import { useSelector, useDispatch } from 'react-redux';
import { Circles } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import Loader from "../layout/Loader";
import scholarImage from '../../../assets/images/scholar.png';
import enfjImage from '../../../assets/images/enfj-protagonist.svg';
import enfpImage from '../../../assets/images/enfp-campaigner.svg';
import entjImage from '../../../assets/images/entj-commander.svg';
import entpImage from '../../../assets/images/entp-debater.svg';
import esfjImage from '../../../assets/images/esfj-consul.svg';
import esfpImage from '../../../assets/images/esfp-entertainer.svg';
import estjImage from '../../../assets/images/estj-executive.svg';
import estpImage from '../../../assets/images/estp-entrepreneur.svg';
import infjImage from '../../../assets/images/infj-advocate.svg';
import infpImage from '../../../assets/images/infp-mediator.svg';
import intjImage from '../../../assets/images/intj-architect.svg';
import intpImage from '../../../assets/images/intp-logician.svg';
import isfjImage from '../../../assets/images/isfj-defender.svg';
import isfpImage from '../../../assets/images/isfp-adventurer.svg';
import istjImage from '../../../assets/images/istj-logistician.svg';
import istpImage from '../../../assets/images/istp-virtuoso.svg';
import CS34Icon1 from '../../../assets/images/achiever.png';
import CS34Icon2 from '../../../assets/images/focus.png';
import CS34Icon3 from '../../../assets/images/competition.png';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Select } from 'antd';

ChartJS.register(
  ArcElement,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const Profile = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.profile' });

  const gallupObjectState = useSelector(state => state.gallup.gallupObject);
  const gallupProfReports = useSelector(state => state.gallup.profReports);
  const [mitLabels, setMitLabels] = useState();
  const [mitValues, setMitValues] = useState();
  const [pieChartValues, setPieChartValues] = useState();
  const [pieChartLabels, setPieChartLabels] = useState();
  const [CS34Colors, setCS34Colors] = useState([]);
  const [CS34Content, setCS34Content] = useState({});
  const CS34Icons = [CS34Icon1, CS34Icon2, CS34Icon3];
  const [showPopup, setShowPopup] = useState(false);
  const [garnerPageState, setGarnerPageState] = useState({});

  const data = {
    labels: mitLabels,
    datasets: [
      {
        label: false,
        data: mitValues,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      r: {
        ticks: {
          backdropColor: 'transparent',
        },
        min: 0,   // Установите минимальное значение
        max: 100, // Установите максимальное значение
      }
    }
  };

  const pieChartData = {
    labels: pieChartLabels,
    datasets: [
      {
        label: '',
        data: pieChartValues,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };



  const { gallupId } = useParams();
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const loading = useSelector(state => state.profile.loading);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const reports = [
    { label: t('Professions 1'), url: gallupObjectState.pdf_similarities_new_download },
    { label: t('Professions 2'), url: gallupObjectState.pdf_similarities_download },
    { label: t('report1'), url: gallupObjectState.report1_url },
    { label: t('report2'), url: gallupObjectState.report2_url },
    { label: t('report3'), url: gallupObjectState.report3_url },
    { label: t('report4'), url: gallupObjectState.report4_url },
    { label: t('report5'), url: gallupObjectState.report5_url },
    { label: t('report6'), url: gallupObjectState.report6_url },
  ].filter(report => report.url);

  const [selectedReport, setSelectedReport] = useState(null); 

  console.log(selectedReport);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleReportSelection = (e, item) => {
    if (e.includes('all')){
      setSelectedReport(reports.map(report => report.url));
    }else{
      setSelectedReport(e); 
    }
  };

  console.log(selectedReport)

  const handleDownloadReport = () => {
      if (selectedReport) {
        selectedReport.forEach((url, index) => {
          const link = document.createElement('a');
          link.href = url;
          link.target = '_blank';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      };
    
      togglePopup(); 
    }

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    } else {
      dispatch(fetchGallupById(gallupId));
      dispatch(fetchGallupProfReports(gallupId));
    }
  }, [dispatch, isLoggedIn, navigate, gallupId]);

  useEffect(() => {
    if (gallupObjectState.hasOwnProperty('MIT')) {
      let mitLabels = [];
      let mitValues = [];
      try {
        let mitRadarData = JSON.parse(gallupObjectState.MIT);
        Object.keys(mitRadarData).forEach(item => {
          mitLabels.push(item);
        });
        Object.values(mitRadarData).forEach(item => {
          mitValues.push(parseInt(item));
        });
      } catch (exc) {
        let mitRadarData = {
          "Linguistic": "8",
          "Logical-mathematical": "8",
          "Musical": "8",
          "Bodily-kinesthetic": "8",
          "Spatial": "8",
          "Interpersonal": "8",
          "Intra-personal": "8",
          "Naturalistic": "8",
          "Existential": "8"
        };
        Object.keys(mitRadarData).forEach(item => {
          mitLabels.push(item);
        });
        Object.values(mitRadarData).forEach(item => {
          mitValues.push(parseInt(item));
        });
      }
      setMitLabels(mitLabels);
      setMitValues(mitValues);
    }
    if (gallupObjectState.top_talants) {
      const talents = gallupObjectState.top_talants;
      let colors = [];
      let content = {};
      talents.forEach((talent, index) => {
        switch (talent[1]) {
          case 'Blue':
            colors[index] = '#0070cd';
            break;
          case 'Green':
            colors[index] = '#00945d';
            break;
          case 'Purple':
            colors[index] = '#7b2481';
            break;
          case 'Orange':
            colors[index] = '#e97200';
            break;
          default:
            break;
        }
        content[talent[0]] = talent[2];
      });
      setCS34Colors(colors);
      setCS34Content(content);
    }
  }, [gallupObjectState]);

  useEffect(() => {
    if (gallupProfReports) {
      let frequenciesValues = [];
      let processedProfessions = new Set();
      gallupProfReports.slice(0, 100).forEach(profReport => {
        let profession = profReport.Professions;
        let sphere = profReport.Field;
        if (processedProfessions.has(profession)) {
          return;
        }
        let existingEntry = frequenciesValues.find(entry => entry.sphere === sphere);
        if (existingEntry) {
          existingEntry.frequency += 1;
        } else {
          if (frequenciesValues.length < 5) {
            frequenciesValues.push({
              sphere: sphere,
              frequency: 1
            });
          }
        }
        processedProfessions.add(profession);
      });
      setPieChartValues(frequenciesValues.map(obj => obj.frequency));
      setPieChartLabels(frequenciesValues.map(obj => obj.sphere));
    }
  }, [gallupProfReports]);

  useEffect(() => {
    setGarnerPageState({ data: { labels: mitLabels, datasets: [{ label: false, data: mitValues }] }, options: { scales: { r: { min: 0, max: 100 } } } });
  }, [mitValues, mitLabels]);

  if (loading) {
    return <Loader />;
  }

  const getMbtiImage = (mbti) => {
    switch (mbti) {
      case 'ENFJ': return enfjImage;
      case 'ENFP': return enfpImage;
      case 'ENTJ': return entjImage;
      case 'ENTP': return entpImage;
      case 'ESFJ': return esfjImage;
      case 'ESFP': return esfpImage;
      case 'ESTJ': return estjImage;
      case 'ESTP': return estpImage;
      case 'INFJ': return infjImage;
      case 'INFP': return infpImage;
      case 'INTJ': return intjImage;
      case 'INTP': return intpImage;
      case 'ISFJ': return isfjImage;
      case 'ISFP': return isfpImage;
      case 'ISTJ': return istjImage;
      case 'ISTP': return istpImage;
      default: return scholarImage;
    }
  };

  return (
    <div className={`bg-[#eaf8fc]`}>
      <div className="container-dashboard mx-auto flex flex-col xl:flex-row gap-4 min-h-screen pb-4">
        <div className="w-full xl:w-1/4 flex flex-col gap-4">
          <UserCard mode={'profile'} gallupId={gallupId} name={gallupObjectState.Name} school={gallupObjectState.School}
            dateOfBirth={gallupObjectState.Date_of_birth} grade={gallupObjectState.Grade}
            mit={gallupObjectState.MIT}
            mbti={gallupObjectState.MBTI} spheres={gallupObjectState.Selected_fields}
            photoURL={gallupObjectState.photo_url}
            personType={gallupObjectState.Person_type}
            company={gallupObjectState.Company}
            business={gallupObjectState.Business} />
          <div className="lg:block hidden">
            {/* <ConsultCard mode={'profile'} /> */}
          </div>
        </div>
        <div className="w-full xl:w-3/4 flex flex-col gap-4">
        <div className="flex lg:justify-between lg:flex-row lg:items-center items-start flex-col w-full gap-4">
          <div className='text-left md:text-[32px] text-[20px] md:leading-[37.6px] leading-[23.5px] font-bold'>
            {t('testResults')}
          </div>
          <div className='flex gap-2 lg:w-fit w-full lg:*:w-fit *:w-full *:px-4 md:*:px-8 *:py-4 md:text-[16px] text-[14px]
							md:leading-[18.8px] leading-[16.45px] font-semibold text-white *:rounded-[10px] *:flex *:flex-row *:gap-1 *:items-center *:justify-center'>
            <button type='button' className='p-2 relative lg:bg-[#474BB1] bg-[#2596E8]' onClick={togglePopup}>
              <div>{t('downloadReports')}</div>
              <ChevronDownIcon className={`text-white lg:hidden h-[2em]`} />
              {showPopup && (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className='cursor-default absolute top-0 lg:right-0 right-auto lg:left-auto left-0 bg-white p-3 rounded-[10px] flex flex-col gap-3 ring-1 ring-[#CECECE]
										text-black text-[20px] leading-[23.5px]'>

                  <div className={`text-[16px] leading-[18.8px] font-bold flex items-center justify-center`}>
                    <div>{t('downloadReports')}</div>
                    <XMarkIcon className={`size-[2em] cursor-pointer absolute right-2`} onClick={togglePopup} />
                  </div>

                  <div className="flex flex-col gap-1 text-[16px]">
                  <Select 
                    mode="multiple"
                    allowClear
                    placeholder={t('selectReport')}
                    value={selectedReport}
                    // options={reports.map((report) => {
                    //   return {
                    //     label: report.label,
                    //     value: report.url
                    //   }
                    // })}
                    onChange={handleReportSelection}
                  >
                    <Select.Option value="all">
                      Выбрать все
                    </Select.Option>
                    {
                      reports?.map((report, index) => (
                        <Select.Option
                          value={report.url}
                        >
                          {report.label}
                        </Select.Option>
                      ))
                    }
                  </Select>
                  {/* <select
                    value={selectedReport || ""}
                    onChange={handleReportSelection}
                    className="p-2 rounded-[10px] bg-[#F4F4F4] text-black"
                  >
                    <option value="" disabled>
                      {t('selectReport')}
                    </option>
                    {reports.map((report, index) => (
                      <option key={index} value={report.url}>
                        {report.label}
                      </option>
                    ))}
                  </select> */}
                </div>

                  <button onClick={handleDownloadReport}
                    className='bg-[#2596E8] p-2 rounded-[10px] px-20 py-3 text-[16px] leading-[18.8px] text-white'>
                    {t('download')}
                  </button>
                </div>
              )}
            </button>
            <Link to={`/reports/1/${gallupId}`}
              className={`block bg-[#474BB1]`}>
              {t('goToResults')}
            </Link>
          </div>
        </div>

          <div
            className="grid lg:grid-cols-3 grid-cols-1 gap-4 *:bg-[#FFFFFF] *:p-4 *:rounded-[25px] *:shadow-lg *:flex *:flex-col *:text-start *:gap-4">
            <Link className={`block cursor-pointer`} to={`/test_result/${gallupId}/MBTI`}>
              <div className='sm:text-[16px] md:text-[18px] lg:text-[24px] font-bold'>{t('MBTI16')}</div>
              <div className='flex flex-row gap-2 h-full'>
                <div className={`basis-2/3`}>
                  <img src={getMbtiImage(gallupObjectState.MBTI)} className='h-full object-cover' alt={''} />
                </div>
                <div className={`flex flex-col gap-0 sm:gap-6 lg:gap-0 text-center mt-[10%] basis-1/3`}>
                  <span
                    className='text-[32px] sm:text-[60px] lg:text-[32px] text-[#714C5D] leading-[37.6px] font-bold'>
                    {gallupObjectState.hasOwnProperty('MBTI') && gallupObjectState.MBTI}
                  </span>
                  <span
                    className={`text-black text-[16px] sm:text-[30px] lg:text-[16px] leading-[18.8px]`}>{gallupObjectState.MBTI_translation}</span>
                </div>
              </div>
            </Link>
            <Link className={`block cursor-pointer`} to={`/test_result/${gallupId}/GarnerMIT`} state={garnerPageState}>
              <span className='sm:text-[16px] md:text-[18px] lg:text-[24px] font-bold'>GARDNER MIT</span>
              <Radar data={{ labels: mitLabels, datasets: [{ data: mitValues, backgroundColor: 'rgba(255, 99, 132, 0.2)', borderColor: 'rgba(255, 99, 132, 1)', borderWidth: 1 }] }} options={options} className={`lg:max-h-[15em]`} />
            </Link>
            <Link className={`block cursor-pointer`} to={`/test_result/${gallupId}/ClifftonStrength`}>
              <div className='font-bold lg:text-[24px] lg:leading-[28.2px]'>CliftonStrength 34</div>
              {(CS34Colors && CS34Content && Object.keys(CS34Content).length === 3 && Object.keys(CS34Colors).length === 3) ? (
                <div className='grid grid-cols-1 gap-2 *:p-3 h-full *:h-full *:flex *:items-center *:rounded-[15px]'>
                  {
                    CS34Colors.map((color, idx) => (
                      <div style={{ backgroundColor: color }} key={idx}>
                        <span className="pr-4">
                          <img src={CS34Icons[idx]} className="size-10" alt="Achiever" />
                        </span>
                        <div className="flex flex-col text-white text-left">
                          <span className="font-bold">{Object.keys(CS34Content)[idx]}</span>
                          <span className='text-[12px]'>{Object.values(CS34Content)[idx]}</span>
                        </div>
                      </div>
                    ))
                  }
                </div>
              ) : (
                <div className={`size-full grid place-items-center`}>
                  <Circles
                    color="#cdebff"
                    height={100}
                    width={100}
                    timeout={5000}
                  />
                </div>
              )}
            </Link>
          </div>

          <div className='text-left md:text-[32px] text-[20px] md:leading-[37.6px] leading-[23.5px] font-bold'>
            {t('yourProfessions')}
          </div>

          <div className="flex justify-between flex-col 2xl:flex-row gap-4 *:*:max-h-[30em] *:shadow-lg">
            <div className="lg:basis-2/5 bg-[#FFFFFF] p-4 rounded-[25px] flex flex-col">
              <h2
                className='sm:text-[16px] md:text-[18px] lg:text-[24px] font-bold leading-[28px]'>{t('selectedSpheres')}</h2>
              <div className='xs:h-[25em] grid place-items-center p-2'>
                <Pie
                  className={``}
                  data={pieChartData}
                  options={{
                    spacing: 20,
                    responsive: true
                  }} />
              </div>
            </div>
            <div className="lg:basis-3/5 bg-[#FFFFFF] p-4 rounded-[25px] text-[14px] select-none">
              <table
                className='block overflow-auto rounded-[9px] relative table-auto text-left h-full'>
                <thead
                  className={`sticky top-[-1px] after:absolute after:block after:bg-[#C1C1C1] after:w-full after:h-[2px] bottom-0 inset-x-0`}>
                  <tr
                    className={`bg-white md:text-[18px] md:leading-[21.15px] md:[&>*:not(:first-child)]:ps-4 [&>*:not(:first-child)]:ps-2`}>
                    <th>{t('position')}</th>
                    <th>{t('sphere')}</th>
                    <th>{t('profession')}</th>
                    <th>% {t('matching')}</th>
                  </tr>
                </thead>
                <tbody>
                  {gallupProfReports && gallupProfReports.map((profReport, idx) => {
                    return (
                      <tr
                        key={idx}
                        className="even:bg-[#F8F8F8] *:py-4 md:*:ps-4 *:ps-2">
                        <td className="md:text-[20px] md:leading-[23.5px]">{profReport.Place}</td>
                        <td className="md:text-[16px] md:leading-[18.8px]">{profReport.Field}</td>
                        <td
                          className="font-bold md:text-[16px] md:leading-[18.8px]">{profReport.Professions}</td>
                        <td
                          className="font-semibold md:text-[18px] md:leading-[21.15px]">{profReport['Percentage fitting']}</td>
                      </tr>
                    );
                  })}
                </tbody>
                <tbody
                  className={`${gallupProfReports?.length < 1 ? 'block' : 'hidden'} size-full grid place-items-center md:text-[20px] md:leading-[23.5px] py-10`}>
                  <tr>
                    <td>
                      <Circles
                        color="#cdebff"
                        height={100}
                        width={100}
                        timeout={5000}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="lg:hidden">
              {/* <ConsultCard mode={'profile'} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;